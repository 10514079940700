import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import Back from '../../../components/Back';
import ImgSemifull from '../../../components/ImgSemifull';
import ImgFit from '../../../components/ImgFit';
import ImgSmall from '../../../components/ImgSmall';
import ImgFull from '../../../components/ImgFull';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Title2 from '../../../components/Title2';
import Flex from '../../../components/Flex';
import Helmet from 'react-helmet';
import Logo from './logo-cammy.svg';
import PersonDetectionSvg from './persondetection.svg';
import InstallationSvg from './installation.svg';
import ControlsSvg from './controls.svg';
import ScheduleSvg from './schedule.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 4,
  isWork: true,
  url: 'cammy',
  title: 'Cammy',
  subtitle: 'No false alarms',
  path: '/work/cammy',
  excerpt: `No false alarms`,
};

const CammyPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro full bg={styles.colors.cammy.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          <ImgSmall img={PersonDetectionSvg} style="" />
          <p className='highlight'>Cammy was one of the first to offer person recognition alarms.</p>
          <p>For most customers, it is the first time they have a security camera at their home. The experience and reliability are the two main pillars of the project.</p>
        </Intro>

        <Section bg='#fff'>
          <Title1 title='Installation' />
          <p>The first step of the user journey is the installation of the cameras. Behind the scenes, programmers put a lot of thought to minimise friction, but customers would need to prepare the camera and connect it to their network before using it.</p>
          <ImgFit img={InstallationSvg} />
          <p>We designed two different flows to scan the network by doing it manually or using the QR code.</p>
          <Flex>
            <ImgFit img={data.installQr.childImageSharp.fluid.src} style="rounded borderbright" />
            <ImgFit img={data.installWifi.childImageSharp.fluid.src} style="rounded borderbright" />
          </Flex>
        </Section>

        <Section dark full bg="#002a35">
          <Title1 title='Safe place' />
          <ImgFit img={data.liveView.childImageSharp.fluid.src} style="rounded borderDark" />
          <p>The camera player is a viewer for real-time video and recorded footage. The thumbnails make it easy to access to any other camera, and the controls can zoom, pan and tilt indoor cameras.</p>
          <ImgSmall img={ControlsSvg} />
          <Title2 title='Custom alarms' />
          <ImgFit img={data.alarms.childImageSharp.fluid.src} />
          <p>The alarms can be manual or auto setup. The auto experience makes it very simple to use as the alarm only works when people are out of the geofence area during the selected time.</p>
          <ImgSemifull img={ScheduleSvg} />

        </Section>

        <Section white full bg="#ffffff">
          <Title1 title='Design system' />
          <p>Cammy's design system allowed us to make the language and design consistent across platforms.</p>
          <ImgFull img={data.ds.childImageSharp.fluid.src} />
          <p>Coherent UX helps users to feel familiar with the product.</p>
        </Section>
        <Section fit bg='#292929'>
          <ImgFull fit img={data.tv.childImageSharp.fluid.src} />
        </Section>
      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default CammyPage;

export const query = graphql`
  query GatsbyCammyQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }

    installQr: file(relativePath: { eq: "work/cammy/install-qr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    liveView: file(relativePath: { eq: "work/cammy/player-event.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          src
        }
      }
    }
    installWifi: file(relativePath: { eq: "work/cammy/install-wifi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    alarms: file(relativePath: { eq: "work/cammy/alarms.png" }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          src
        }
      }
    }
    tv: file(relativePath: { eq: "work/cammy/tvapp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2900) {
          src
        }
      }
    }
    cameras: file(relativePath: { eq: "work/cammy/cameras.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    ds: file(relativePath: { eq: "work/cammy/cammy-ds.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          src
        }
      }
    }

  }
`;