import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    padding-right: ${styles.spaces.xLarge};
  }
  & > *:last-child {
    padding-right: 0;
  }
  @media ${styles.breakpoints.mobile} {
    flex-wrap: wrap;
    & > * {
      padding-right: 0;
    }
  }
`;

class FlexComponent extends React.Component {
  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    );
  }
}

export default FlexComponent
